import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";
import hljs from "highlight.js";


let MdPreviewComponent = null;

export const getMdPreviewComponent = () => {
  if (!MdPreviewComponent) {
    MdPreviewComponent = VMdPreview.use(githubTheme, {
      Hljs: hljs,
    });
  }

  return {
    MdPreviewComponent,
  }
}
